<template>
  <div class="AcdList page">
    <a-button type="primary" style="margin-bottom: 15px" @click="handleAdd">
      <template #icon><PlusOutlined /></template>
      添加
    </a-button>
    <a-table
      :columns="columns"
      :data-source="data"
      :pagination="{
        current: page,
        total: total,
        pageSize: limit,
        showSizeChanger: true,
        showTotal: (total) => `总共 ${total} 条`,
        pageSizeOptions: ['20', '50', '100'],
      }"
      rowKey="key"
      @change="pageChange"
    >
      <template #operation="{ record }">
        <a-button
          type="primary"
          size="small"
          style="margin-right: 4px"
          @click="handleEdit"
          >编辑</a-button
        >
        <a-popconfirm
          title="是否确定删除?"
          ok-text="确定"
          cancel-text="取消"
          @confirm="handleDel(record)"
        >
          <a-button size="small">删除</a-button>
        </a-popconfirm>
      </template>
    </a-table>
    <a-modal
      v-model:visible="visibleAdd"
      title="添加"
      centered
      :maskClosable="false"
      okText="确认"
      cancelText="取消"
      :width="600"
      @ok="okAdd"
    >
      <a-form :model="formAdd" class="formAdd">
        <a-form-item label="班组名称" required>
          <a-input placeholder="请输入" />
        </a-form-item>
        <a-form-item label="呼叫分配" required>
          <a-select placeholder="请选择" v-model:value="formAdd.call">
            <a-select-option value="1">轮循</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal
      v-model:visible="visible"
      title="编辑"
      centered
      :maskClosable="false"
      okText="确认"
      cancelText="取消"
      @ok="handleOk"
      :width="800"
    >
      <a-form class="left-form">
        <a-form-item label="描述">
          <a-input placeholder="请输入" />
        </a-form-item>
        <a-form-item label="进入提示音">
          <a-input placeholder="请输入" />
        </a-form-item>
        <a-form-item label="等待音乐">
          <a-select placeholder="请选择">
            <a-select-option value="1">acd/等待音乐.wav</a-select-option>
            <a-select-option value="2">8002</a-select-option>
            <a-select-option value="3">8003</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="分配策略">
          <a-input placeholder="请输入" />
        </a-form-item>
        <a-form-item label="坐席线路组">
          <a-input placeholder="请输入" />
        </a-form-item>
        <a-form-item label="嘟声时间">
          <a-input placeholder="请输入" />
        </a-form-item>
        <a-form-item label="隐藏主叫">
          <a-input placeholder="请输入" />
        </a-form-item>
        <a-form-item label="记忆坐席">
          <a-input placeholder="请输入" />
        </a-form-item>

        <a-form-item label="等待超时时间">
          <a-input placeholder="请输入" />
        </a-form-item>
        <a-form-item label="等待超时提示">
          <a-input placeholder="请输入" />
        </a-form-item>
        <a-form-item label="等待按键时间">
          <a-input placeholder="请输入" />
        </a-form-item>
        <a-form-item label="继续等待按键">
          <a-input placeholder="请输入" />
        </a-form-item>

        <a-form-item label="报工号模式">
          <a-input placeholder="请输入" />
        </a-form-item>
        <a-form-item label="工号前面的声音">
          <a-input placeholder="请输入" />
        </a-form-item>
        <a-form-item label="工号后面的声音">
          <a-input placeholder="请输入" />
        </a-form-item>

        <a-form-item label="开始提示音">
          <a-input placeholder="请输入" />
        </a-form-item>
        <a-form-item label="按键等待时间">
          <a-input placeholder="请输入" />
        </a-form-item>
        <a-form-item label="有效按键规则">
          <a-input placeholder="请输入" />
        </a-form-item>
        <a-form-item label="存储按键变量">
          <a-input placeholder="请输入" />
        </a-form-item>
        <a-form-item label="安检错误提示音">
          <a-input placeholder="请输入" />
        </a-form-item>
        <a-form-item label="结束提示音">
          <a-input placeholder="请输入" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { reactive, toRefs } from "@vue/reactivity";
import { PlusOutlined } from "@ant-design/icons-vue";
const data = [
  {
    key: "1",
    name: "默认",
    age: "轮循",
    address: "西湖区湖底公园1号",
  },
  {
    key: "2",
    name: "胡彦祖",
    age: "轮循",
    address: "西湖区湖底公园1号",
  },
];
const columns = [
  {
    title: "名称",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "分配",
    dataIndex: "age",
    key: "age",
  },
  // {
  //   title: "住址",
  //   dataIndex: "address",
  //   key: "address",
  // },
  {
    title: "操作",
    key: "operation",
    slots: {
      customRender: "operation",
    },
    fixed: "right",
    width: "135px",
  },
];
export default {
  name: "AcdList",
  components: { PlusOutlined },
  setup() {
    const state = reactive({
      page: 1,
      total: undefined,
      limit: 20,
      visible: false,
      visibleAdd: false,
    });
    const formAdd = reactive({
      name: undefined,
      call: "1",
    });
    const pageChange = (e) => {
      state.page = e.current;
      state.limit = e.pageSize;
    };
    const handleDel = (item) => {
      console.log(item);
    };
    const handleAdd = () => {
      // state.visible = true;
      state.visibleAdd = true;
    };
    const handleEdit = () => {
      state.visible = true;
    };

    const handleOk = () => {
      state.visible = false;
    };
    const okAdd = () => {
      state.visibleAdd = false;
    };
    return {
      ...toRefs(state),
      data,
      columns,
      pageChange,
      handleDel,
      handleAdd,
      handleOk,
      handleEdit,
      formAdd,
      okAdd,
    };
  },
};
</script>
<style >
.left-form {
  display: flex;
  flex-wrap: wrap;
  margin-left: 20px;
}
.left-form .ant-form-item {
  width: 320px;
}
.left-form .ant-form-item:nth-child(2n + 1) {
  margin-right: 70px;
}
.left-form .ant-form-item-label {
  min-width: 120px;
  text-align: left;
}
.formAdd {
  display: flex;
}
.formAdd .ant-form-item {
  width: 240px;
}
.formAdd .ant-form-item:nth-child(1) {
  margin-right: 40px;
}
</style>
<style scoped>
.AcdList {
  overflow: auto;
  padding: 15px;
}
</style>